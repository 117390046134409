import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { defaultLocale, locales } from '@/navigation';

type ExcludedLocales = Exclude<typeof locales[number], typeof DEFAULT_DAYJS_LOCALE>

const DEFAULT_DAYJS_LOCALE = defaultLocale;
const importedLocales: Record<ExcludedLocales | string, ILocale> = {};

export const DEFAULT_FORMAT = 'MM/DD/YY';
export async function updateDayjsLocale (locale: typeof locales[number]) {
    try {
        if (locale === dayjs.locale()) return;
        if (locale === DEFAULT_DAYJS_LOCALE) {
            dayjs.locale(DEFAULT_DAYJS_LOCALE);
            return;
        }

        if (!importedLocales[locale]) {
            const loadedModule: { default: ILocale } = await import(`dayjs/locale/${locale}.js`);
            importedLocales[locale] = loadedModule.default;
        }

        const dayjsLocaleObj = importedLocales[locale];


        dayjs.locale(dayjsLocaleObj as ILocale);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

dayjs.extend(isToday);
dayjs.extend((option, dayjsClass) => {
    const oldFormat = dayjsClass.prototype.format;

    dayjs.prototype._format = function (formatString?: string) {
        return oldFormat.bind(this)(formatString || DEFAULT_FORMAT);
    };
});

export default dayjs;
