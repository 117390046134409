export const QUERY_KEYS = {
    USERS: {
        ME: {
            GET: 'USERS_ME_GET'
        }
    },
    TABLES: {
        GET: 'TABLES_GET'
    }
} as const;

export const MUTATION_KEYS = {
    AUTH: {
        SIGN_UP: {
            POST: 'AUTH_SIGN_UP_POST'
        },
        SIGN_IN: {
            POST: 'AUTH_SIGN_IN_POST'
        },
        LOGOUT: {
            POST: 'AUTH_LOGOUT_POST'
        },
        RESET_PASSWORD: {
            CONFIRM: {
                POST: 'AUTH_RESET_PASSWORD_CONFIRM_POST'
            },
            POST: 'AUTH_RESET_PASSWORD_POST'
        }
    }
} as const;
