'use client';

import { Checkbox, PasswordInput, TextInput } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { memo, ReactNode } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useIMask } from "react-imask";

import { FormItem } from "@/app/types/shared";

import PasswordFieldWithStrength from "../password-field-with-strength";
import styles from './index.module.scss';

type BaseFormItemProps<T extends FieldValues> = {
    control: Control<T>
    formItem: FormItem<Path<T>>
};

export default memo(
    function BaseFormItem<T extends FieldValues> (props: BaseFormItemProps<T>) {
        const { control, formItem } = props;
        const { field, fieldState } = useController({ control: control as unknown as Control<FieldValues>, rules: formItem.rules, name: formItem.name });
        const { ref: maskRef, value, setTypedValue } = useIMask(formItem.mask || {},
            {
                onAccept: value => {
                    value !== field.value && field.onChange(value);
                }
            }
        );

        const mergedRef = useMergedRef(
            field.ref,
            maskRef
        );


        const sharedProps = {
            size: formItem.size,
            className: formItem.className
        };

        const sharedTextInputsProps = {
            variant: formItem?.variant || 'filled',
            label: formItem?.label || undefined,
            placeholder: formItem?.placeholder || undefined,
            required: formItem.required,
            error: fieldState.error?.message,
            ref: mergedRef
        };

        let formItemElement: ReactNode = '';

        if (formItem.type === 'checkbox') {
            formItemElement = (
                <Checkbox
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    size={formItem.size}
                    onChange={(event => field.onChange(event.target.checked))}
                    checked={field.value}
                />
            );
        }

        if (formItem.type === 'textInput') {
            formItemElement = (
                <TextInput
                    {...field}
                    {...sharedProps}
                    {...sharedTextInputsProps}
                    withAsterisk={formItem.withAsterisk}
                />
            );
        }


        if (formItem.type === 'passwordInput') {
            if (formItem.showStrengthMeter) {
                formItemElement = (
                    <PasswordFieldWithStrength
                        {...field}
                        {...sharedProps}
                        {...sharedTextInputsProps}
                        withAsterisk={formItem.withAsterisk}
                    />
                );
            } else {
                formItemElement = (
                    <PasswordInput
                        {...field}
                        {...sharedProps}
                        {...sharedTextInputsProps}
                        withAsterisk={formItem.withAsterisk}
                    />
                );
            }
        }


        return (
            <div className={styles.baseFormItem}>
                {formItemElement}
            </div>
        );
    }
);